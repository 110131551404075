/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useLayoutEffect, useState } from 'react';
import i18n from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import PageMode from 'components/Tools/PageMode';
import { DetailInventoryData } from 'utils/interfaces/Log';
import InventoryDetailsTable from './InventoryDetailsTable';
import { detailsRequest } from '../../api/inventory.api';
import { IInventoryDetailsModalProps } from '../../types';

const InventoryDetailsModal: React.FC<IInventoryDetailsModalProps> = ({
  isOpen,
  closeModal,
  inventoryDetailData,
}) => {
  const { t } = useTranslation();

  return (
    <PageMode
      isModal
      disableSubmit
      open={isOpen}
      closeWhenClickOutside
      handleClose={closeModal}
      title={String(t('screens.commom.detail'))}
    >
      <InventoryDetailsTable rows={inventoryDetailData} />
    </PageMode>
  );
};

export default InventoryDetailsModal;
