import api from 'services/api';

import { InventoryData, DetailInventoryData } from 'utils/interfaces/Log';

import { ISearchParamsAPI } from '../types';

export const searchBoxRequest = async (data: ISearchParamsAPI) => {
  return api.patch('Relatorio/ArmazenagemEstoque', {
    ...data,
  });
};

export const getAllOptions = async (clientId: number) => {
  const [deposits, brands, level1, branchs] = await Promise.all([
    api.get(`Common/Deposito?CodigoCliente=${clientId}`),
    api.get(`Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=0`),
    api.get(`Recebimento/searchbranch?CodigoCliente=${clientId}`),
    api.get(`Common/Marca?CodigoCliente=${clientId}`),
  ]);

  return [deposits, level1, branchs, brands];
};

export const getNigel2API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=1&CodigoPai=${codeNivel}`
  );

export const getNigel3API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=2&CodigoPai=${codeNivel}`
  );

export const getNigel4API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=3&CodigoPai=${codeNivel}`
  );

export const getNigel5API = (clientId: number, codeNivel: string) =>
  api.get(
    `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=4&CodigoPai=${codeNivel}`
  );

export const detailsRequest = async (data: InventoryData) =>
  api.patch<DetailInventoryData[]>('Relatorio/ArmazenagemEstoqueDetalhe', {
    ...data,
  });
