export default {
  translations: {
    header: {
      aboutMenu: {
        support: 'Suporte',
        help: 'Ajuda',
        about: 'Sobre',
      },
    },
    screens: {
      receivement: {
        follow_up: 'Acompanhamento',
        turn: 'Voltar',
        receivement: 'Recebimento',
        requestType: 'Tipo Pedido',
        order: 'Pedido',
        invoice: 'Nota Fiscal',
        status: 'Status',
        series: 'Serie',
        product: 'Produto',
        supplier: 'Fornecedor',
        initialDate: 'Data Inicial',
        finalDate: 'Data Final',
        details: 'Detalhes',
        generateConference: 'Gerar Conferência',
        endDivergence: 'Finalizar com Divergência',
        confirmEndDivergence:
          'Você tem certeza que deseja finalizar com divergência?',
        orderFinisherEnd: 'Pedido finalizado com divergência',
        progress: 'Progresso',
        actions: 'Ações',
        dateApproved: 'Data Apro',
        amountSku: 'Qtde. SKU',
        amountArm: 'Qtde. Arm',
        amountRec: 'Qtde. Rec',
        amount: 'Qtde',
        dateReceivement: 'Data Rec',
        dateEnd: 'Fim Rec',
        alertSeach:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido e nota fiscal',
        successFilter: 'resultados encontrados',
        resultsNotFound: 'Resultados não encontrados',
        document: 'Documento',
        receiptDate: 'Data de recebimento',
        endDate: 'Data de fim',
        enterProduct: 'Digite o produto',
        enterBox: 'Digite a caixa',
        enterPallet: 'Digite o palete',
        dateChecked: 'Data conferida',
        size: 'Tam.',
        quantity: 'Qtde. NF',
        divergent: 'Divergência',
        dateReceipt: 'Data Rec.',
        successfullyReversed: 'estornado com sucesso',
        reversedSuccessfully: 'Recebimento Estornado com sucesso',
        isReverse: 'Deseja estornar o recebimento',
        reverse: 'Estornar',
        youLogin: 'Seu Login',
        password: 'Senha',
        youPassword: 'Sua Senha',
        autenticateMsg: 'Ops Verifique seu Login e ou senha!',
        notLeaderMsg: 'Somente o lider pode autorizar o estorno!',
        revertTitleModal: 'Autenticação do Lider',
        volumes: 'Volumes',
        distributionBox: 'Caixa Distribuição',
        campaign: 'Campanha',
      },
      status: {
        canceled: 'Cancelado',
        divergent: 'Divergente',
        inProgress: 'Em Andamento',
        finished: 'Finalizado',
        completedDivergent: 'Finalizado divergente',
        integrated: 'Integrado OK',
        integratedDivergent: 'Integrado Divergente',
        invoiceDivergent: 'NF Divergente',
        pending: 'Pendente',
        qualityFailed: 'Qualidade Reprovada',
        transit: 'Trânsito',
      },
      printing: {
        print: 'Imprimir',
        printing: 'Impressão',
        product: 'Produto',
        quantity: 'Quantidade',
        printingMessage: 'Impressão Enviada! Por favor, verifique!',
        messageRequired: 'Preencha os campos obrigatórios!',
        oneFilds: 'Preencha um dos campos!',
      },
      cardsFilters: {
        results: 'Resultados',
        finished: 'Finalizado',
        completedDivergent: 'Finalizado Divergent',
        inProgress: 'Em Andamento',
        divergent: 'Divergente',
        pending: 'Pendente',
        totalProgress: 'Progresso Total',
        create: 'Criado',
        planned: 'Planejado',
        progress: 'Andamento',
        resupply: 'Ressuprimento',
        completedShipment: 'Embarque Finalizado',
        finishedDivergence: 'Finalizado C/ Divergência',
        insufficientStock: 'Estoque Insuficiente',
        awaitingIntegration: 'Aguardando Integração',
        checkedQuantity: 'Quantidade Conferida',
        pickingQuantity: 'Quantidade Picking',
        totalAmount: 'Quantidade Total',
        startDate: 'Hora Início Separação',
        startTime: 'Hora Início Separação',
        endDate: 'Data Fim do Embarque',
        endTime: 'Hora Fim do Embarque',
        awaitingBilling: 'Aguardando Faturamento',
        awaitingShipment: 'Aguardando Expedição',
        awaitingConference: 'Aguardando Conferência',
        shipmentFinished: 'Expedição Finalizada',
        awaitingRelease: 'Aguardando Liberação',
        divergentQuantity: 'Quantidade Divergente',
        amountReceived: 'Quantidade Recebida',
        waitingConference: 'Aguardando Conferência',
        waitingShipment: 'Aguardando Embarque',
        orderCancelled: 'Pedido Cancelado',
        waitingRelease: 'Aguardando Liberação',
        waitingScreening: 'Aguardando Triagem',
        screeningCompleted: 'Triagem Finalizada',
        update: 'Atualizar',
        delivered: 'Entregue',
      },
      structureAdresses: {
        type: 'Tipo',
        description: 'Descrição',
        isActive: 'Está Ativo?',
        noFound: 'Nenhum endereço encontrado',
        adreessesFound: 'endereços encontrados',
        structureEditSuccess: 'Estrutura editada com sucesso',
        successDeleteRecordes: 'Registro deletado com sucesso',
        notFoundRecordes: 'Nenhum registro foi encontrado.',
        wasFound: 'Foi encontrado',
        records: 'registro(s)',
        someError: 'Algo deu errado com a requisição',
        structureCreate: 'Estrutura criada com sucesso!',
        structure: 'Estrutura',
        titleScreen: 'Criar Estrutura',
        newStrucutureBtn: 'Nova Estrutura',
        create: 'Criar',
        createStructureTitle: 'Criar uma estrutura',
        edit: 'Editar',
        successfullyDeleted: 'Deletado com sucesso!',
        isDeleted: 'Deseja deletar ?',
        status: 'Status',
        actions: 'Ações',
        delete: 'Excluir',
        statusTrue: 'Ativo',
        statusFalse: 'Inativo',
      },
      address: {
        deposit: 'Deposito',
        type: 'Tipo',
        status: 'Status',
        adresses: 'Endereço',
        toWalk: 'Andar',
        road: 'Rua',
        modules: 'Modulos',
        level: 'Nivel',
        position: 'Posição',
        resulpply: 'Ressuprimento',
        capacity: 'Capacidade',
        resu: 'Ressu.',
        actions: 'Ações',
        delete: 'Excluir',
        edit: 'Editar',
        editStatus: 'Editar Status',
        newAddress: 'Novo Endereço',
        storage: 'Armazenagem',
        notFound: 'Não há registros de enderecos',
        foundResults: 'resultado(s) encontrados',
        error: 'Erro',
        branch: 'Filial',
        shippingCompany: 'Transportadora',
        branchsNotName: 'Não há filiais com esse nome',
        editedSuccessfully: 'Editado com sucesso!',
        saveButtonTitle: 'Salvar',
        notLeave: 'Não deixe o campo de status vazio!',
        updateStatus: 'Status atualizados com sucesso!',
        deleteSuccess: 'Deletado com sucesso',
        isDelete: 'Você tem certeza que deseja apagar o endereço',
        deleteAddress: 'Endereço excluído',
        createAddressTitle: 'Criar Endereço',
        createAddressSuccess: 'Criado com sucesso!',
        addressExist: 'Endereço Existente!',
        importFile: 'Importar Arquivo',
        importSuccess: 'Arquivo importado com sucesso!',
        uploadFile: 'Subir Arquivo',
        choiceFile: 'Escolha o arquivo para fazer upload.',
      },
      logAddress: {
        product: 'Produto',
        address: 'Endereço',
        initialDate: 'Data Incial',
        finalDate: 'Data Final',
        box: 'Caixa',
        pallet: 'Palete',
        type: 'Tipo',
        noRecordes: 'Não foi encontrado nenhum registro!',
        logStorage: 'Log Armazenagem',
        quantityPalete: 'Quantidade Palete',
        quantityBox: 'Quantidade Caixa',
        quantity: 'Quantidade',
        manufacturer: 'Fabricante',
        descriptionProduct: 'Descrição do Produto',
        dateStorage: 'Data Armazenagem',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo produto ou endereço',
      },
      stockPack: {
        title: 'Armazenagem',
        pack: 'Lista',
        search: 'Pesquisar',
        noFound: 'Nenhum endereço encontrado',
        wasFound: 'Produtos encontrados',
        package: 'Pack',
        adress: 'Endereço',
      },
      orderFollowUp: {
        title: 'Pedido',
        followUp: 'Acompanhamento',
        orderType: 'Tipo Pedido',
        client: 'Cliente',
        period: 'Periodo',
        clientBranch: 'Cliente/Filial',
        receive: 'Recebido',
        planning: 'Planejamento',
        finished: 'Finalizado',
        endShipment: 'Fim Embarque',
        resultsNotFound: 'Resultados não encontrados.',
        recordsFound: 'Foi encontrado',
        clientAndBranch: 'Cliente/Filial',
        quantity: 'Quantidade',
        received: 'Recebido',
        integrated: 'Integrado',
        check: 'Conferir',
        color: 'Cor',
        orderProgress: 'Progresso Pedido',
        branchsNotName: 'Não há filiais com esse nome',
        printing: 'Imprimindo',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido',
        separated: 'Separado',
        checked: 'Conferido',
        dispatched: 'Expedido',
        embarked: 'Embarcado',
        separationStart: 'Data Inicio Separação',
      },
      reverseOrder: {
        errorTheRequest: 'Erro: O pedido',
        thisStatusCreate: 'já esta como o status criado!',
        orderStatus: 'Status do pedido',
        changedSuccessfully: 'alterado com sucesso!',
        noRecordesFound: 'Nenhum registro foi encontrado.',
        reverse: 'Estornar',
        wave: 'Onda',
        editStatusTitle: 'Editar Status - Pedido:',
        dateOrder: 'Data Pedido',
        quantityPieces: 'Quantidade Peças',
        newWave: 'Nova Onda',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido ou onda!',
      },
      createWave: {
        included: 'incluido',
        onWave: 'na onda.',
        notIncluded: 'não incluido',
        branchsNotName: 'Não há filiais com esse nome',
        picking: 'Picking',
        createWave: 'Criar onda',
        taxUnit: 'UF',
        customerBranch: 'Cliente/Filial',
        quantityPieces: 'Qtde Peças',
        newWave: 'Nova Onda',
        selectedOrder: 'pedido(s) selecionado(s)',
        priority: 'Prioridade',
        splitVolume: 'Dividir Volume?',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
      },
      followPicking: {
        branchsNotName: 'Não há filiais com esse nome',
        picking: 'Picking',
        editUser: 'Editar Usuário',
        changeUser: 'Alterar Usuário',
        editUserSuccess: 'Usuário editado com sucesso',
        followPicking: 'Acompanhamento Conferência',
        separationFollowUp: 'Acompanhamento Separação',
        priority: 'Prioridade',
        customerBranch: 'Cliente/Filial',
        quantityPieces: 'Qtde. Peças',
        quantityPicking: 'Qtde. Picking',
        quantityChecked: 'Qtde. Conferida',
        dateOrder: 'Data Pedido',
        dateFinal: 'Data Final',
        document: 'Documento',
        startDate: 'Data de incío:',
        finalDate: 'Data de fim:',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pela onda e pedido.',
      },
      pickingConference: {
        product: 'Produto:',
        isResult: 'conferido com sucesso. Pedido:',
        picking: 'Picking',
        conference: 'Conferência',
        conferenceDetails: 'Detalle de Conferência',
        finalize: 'Finalizar!',
        conferenceFinished: 'Conferência Finalizada!',
        boxAlreadyAddressed: 'Caixa já endereçada!',
        boxNotExist: 'Caixa não existe!',
        boxNotBelong: 'Caixa não pertence ao pedido!',
        invalidBarcode: 'Código de Barras inválido',
        validatedSucces: 'Caixa e EAN validados com sucesso!',
        quantityExceeded: 'Quantidade excedida!',
        insertBox: 'Favor inserir uma caixa para realizar a coleta!',
        endConference: 'Você tem certeza que deseja finalizar a conferência?',
        barCode: 'Cód. Barra',
        code: 'Código de Barras',
        box: 'Caixa',
        check: 'Conferir',
        conferred: 'Conferido',
        qty: 'Qtde.',
        boxType: 'Tipo de caixa',
        bathModalTitle: 'Lote',
        selectBath: 'Selecione o Lote',
        validity: 'Validade',
        checkedProduct: 'Produto Conferido',
        alreadyPackage: 'Produto já embalado!',
        invalidBarcodeByProduct: 'Código inválido para esse pedido!',
        notBarcode: 'Preencha o código de barras!',
        grouping: 'Agrupamento',
        depositCod: 'Cod. Depósito',
        despositDesc: 'Desc. Depósito',
        addressCode: 'Cod. Endereço',
        addressDesc: 'Desc. Endereço',
        quantityVisit: 'Qtde. Visítas',
        quantityParts: 'Qtde. Peças',
        reportVisit: 'Visíta Picking',
        confirmRevertMsg: 'Confirmar estornar o produto',
      },
      transferenceFollowUp: {
        transfer: 'Transferência',
        origin: 'Origem',
        destiny: 'Destino',
        batch: 'Lote',
        validity: 'Validade',
        unit: 'Unidade',
        type: 'Tipo',
        transferDate: 'Data de Transferência',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre por unidade.',
      },
      expeditionFollowUp: {
        expedition: 'Expediçao',
        query: 'Consulta',
        state: 'Estado',
        dispatchDate: 'Data Expedição',
        customerBranch: 'Cliente/Filial',
        dispatchUser: 'Usuário Expedição',
        codeOrder: 'Cod. Pedido',
        codeExpedition: 'Cod. Expedição',
        validProduct: 'Produto valido',
        startDate: 'Data Inicio Expedição',
        endDate: 'Data Fim Expedição',
        branchsNotName: 'Não há filiais com esse nome',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
        notFound: 'Detalhes da expedição não encontrado!',
      },
      boarding: {
        boarding: 'Embarque',
        packingList: 'Romaneio',
        customerBranch: 'Cliente/Filial',
        invoiceQuantity: 'Qtde. NF',
        volumeQuantity: 'Qtde. Volume',
        exportButton: 'Exportar Romaneio(s) em PDF',
        requestCode: 'Código do pedido',
        orderType: 'Tipo Pedido',
        packingDetails: 'Detalhes Romaneio',
        recordFound: 'registro(s) encontrados',
        agent: 'Agente',
        enterAgent: 'Digite o agente',
        agentError: 'Erro. Conecte-se ao seu agente!',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo romaneio.',
      },
      manualOrder: {
        manualOrder: 'Cadastro Manual',
        fileUpload: 'Upload de arquivo',
        register: 'Cadastro',
        requireFilds: 'Preencha os campos necessários',
        receipt: 'Recebimento:',
        successfullyCreated: 'criado com sucesso!',
        order: 'Pedido:',
        created: 'criado!',
        operation: 'Operação',
        chooseOperation: 'Escolha a operação',
        prohibited: 'Entrada',
        exit: 'Saída',
        chooseType: 'Escolha um tipo',
        enterInvoice: 'Digite a nota fiscal',
        series: 'Serie',
        date: 'Data',
        branchCustomer: 'Código cliente ERP / Filial',
        carrierCode: 'Código da Transportadora',
        supplier: 'Fornecedor',
        chooseSupplier: 'Escolha o fornecedor',
        items: 'Itens',
        deletedItems: 'Items deletados',
        deletedItem: 'Item deletado',
        withSuccess: 'com êxito',
        ordem: 'Ordem',
        delete: 'Deletar',
        validData: 'Insira dados válidos',
        addedSuccessfully: 'adicionado com sucesso!',
        nameAndCode: 'Não há produtos com esse nome/código',
        chooseProduct: 'Escolha um produto',
        enterAmount: 'Digite uma quantidade',
        toAdd: 'Adicionar',
        chooseUpload: 'Escolha o arquivo para fazer upload.',
        liftFile: 'Subir Arquivo',
        chooseRegistration: 'Escolha a forma de cadastro',
      },
      parameters: {
        parameters: 'Parâmetro',
        administration: 'Administração',
        successfullyEdited: 'Estrutura editada com sucesso!',
        enterParemeter: 'Por favor digite o parâmetro',
        parametersDescription: 'Por favor digite a descrição do parâmetro',
        value: 'Valor',
      },
      registerProduct: {
        searchProduct: 'Pesquise algum produto!',
        thereProducts: 'Não há produtos',
        productsFound: 'produto(s) encontrados',
        register: 'Cadastro',
        productCode: 'Código do Produto',
        marked: 'Marcado',
        unchecked: 'Desmarcado',
        productEdited: 'Produto Editado sucesso',
        active: 'Ativo',
        level: 'Nível',
        height: 'Altura',
        width: 'Largura',
        length: 'Comprimento',
        weight: 'Peso',
        component: 'Componente',
        brandCode: 'Código Marca',
        receipt: 'Recebimento',
        receiptInDays: 'Recebimento (em dias)',
        exit: 'Saída',
        parameters: 'Parâmetros',
        marketingStructure: 'Estrutura Mercadológica',
        departureInDays: 'Saída (em dias)',
        batchControl: 'Controla Lote',
        controlsValidity: 'Controla Validade',
        controlsReceipt: 'Controla Lote Recebimento',
        receiptValidity: 'Controla Validade Recebimento',
        constrolStorage: 'Controla Lote Armazenagem',
        storageValidity: 'Controla Validade Armazenagem',
        constrolSeparation: 'Controla Lote Separacao',
        separationValidity: 'Controla Validade Separacao',
        checkControl: 'Controla Lote Conferencia',
        validityCheck: 'Controla Validade Conferencia',
        notFieldsEmpty: 'Não deixe campos Vazios',
        colorOrDescription: 'Cor ou Descrição',
        colorDesc: 'Cor/Desc',
        delete: 'Deletar',
      },
      produtivityConference: {
        produtivity: 'Produtividade',
        conference: 'Conferencia',
        name: 'Nome',
        summaryTable: 'Tabela Sintética',
        analyticalTable: 'Tabela Analítica',
        totalHours: 'Total horas',
        ordersTime: 'Pedidos hora',
        amountOrder: 'Qtde. Pedido',
        amountPending: 'Qtde. Pendente',
        amountPackaged: 'Qtde. Embalada',
        addressesTime: 'Qtde. Endereços Hora',
        amountAddresses: 'Qtde. Endereços',
        productTime: 'Produto Hora',
        amountProduct: 'Qtde. Produto',
        researchDate: 'Data Pesquisa',
        fisrtMessage: 'resultado(s) foram encontrados no analítico e',
        lastMessage: 'resultado(s) encontrados no sintético',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo nome.',
        avgHours: 'Média Horas Ini.',
        endAvgHours: 'Média Horas Fim',
        totalHourOrders: 'Pedidos Hora Total',
        quantityHours: 'Qtde. Pedidos Hora T.',
        quantityAddress: 'Qtde. End. Hora T.',
        totalAddress: 'Qtde. Endereços T.',
        productHours: 'Produto Hora Total',
        quantityHoursTotal: 'Qtde. Produto Total',
        printMsg: 'Produto 100% conferido, deseja imprimir?',
        store: 'Loja',
      },
      produtivityStorage: {
        notFieldEmpty: 'Não deixe esse campo vazio',
        choiceDate: 'Escolha uma data inicial e final!',
      },
      reportsStock: {
        boarding: 'Embarque',
        brand: 'Marca',
        address: 'Endereço',
        descriptionProduct: 'Descrição Produto',
        codeRequest: 'Codigo do Pedido',
        reserved: 'Reservado',
        available: 'Disponível',
        inbound: 'Inbound',
        outBound: 'OutBound',
        destinationStore: 'Loja destino',
        reservation: 'Reserva',
        active: 'Ativo',
      },
      invoiceDivergent: {
        reports: 'Relatorios',
        orderDivergent: 'Pedido Divergente',
        series: 'Serie',
        date: 'Data',
        invoiceQuantity: 'Qtde. Nota Fiscal',
        orderQuantity: 'Qtde. Pedido',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido.',
      },
      ressupply: {
        ressupply: 'Ressuprimento',
        origin: 'Origem',
        destiny: 'Destino',
        priority: 'Prioridade',
        reports: 'Relatorios',
        supply: 'Abastecimento',
        creatSupply: 'Criar Abastecimento',
        request: 'Solicitação',
        createSubRomaning: 'Criar Sub-Romaneio',
        SubRomaning: 'Sub Romaneio',
        qtyFirstFase: 'Qtde. Primeira Fase',
        homeCollection: 'Inicio Coleta Primeira Fase',
        endCollection: 'Fim Coleta Primeira Fase',
        qtyLastFase: 'Qtde. Segunda Fase',
        lastCollection: 'Inicio Coleta Segunda Fase',
        endLastCollection: 'Fim Coleta Segunda Fase',
        fase: 'Fase',
      },
      insufficient: {
        insufficient: 'Insuficiente',
        reports: 'Relatorios',
        priority: 'Prioridade',
      },
      separation: {
        reports: 'Relatorios',
        separation: 'Separação',
        quantityPickink: 'Qtde. Picking',
        quantityOrder: 'Qtde. Pedido',
        series: 'Serie',
        nameClient: 'Nome Cliente',
        codeClient: 'Cod. Cliente',
        codShippingCompany: 'Cod. Transportadora',
        descriptionAddress: 'Desc. Endereço',
        billingIssue: 'Emissão Faturamento',
        billingDate: 'Data Faturamento',
        homeSeparation: 'Inicio Separação',
        endSeparation: 'Fim Separação',
      },
      conference: {
        reports: 'Relatorios',
        conference: 'Conferência',
        quantityConference: 'Qtde. Conferida',
        quantityOrder: 'Qtde. Pedido',
        series: 'Serie',
        nameClient: 'Nome Cliente',
        integrationDate: 'Data Integração',
        homeConference: 'Inicio Conferência',
        endConference: 'Fim Conferência',
        finalize: 'Finalizar',
        station: 'Estação',
        selectStation: 'Seleciona a Estação',
        errorMessage:
          'Ops! Verifique se todos os Pedido e Estação estão preenchidos',
      },
      reportReceipt: {
        receipt: 'Recebimento',
        batch: 'Lote',
        enterBach: 'Digite o lote',
        validity: 'Validade',
        reports: 'Relatorios',
        series: 'Serie',
        enterSeries: 'Digite a serie',
        integrationDate: 'Data Integração',
        alertSearch:
          'Por favor, insira datas com uma diferença de no máximo 30 dias ou filtre pelo pedido ou produto!',
      },
      dashboard: {
        awaitingBilling: 'Aguardando Faturamento',
        awaitingIntegration: 'Aguardando Integração',
        conference: 'Conferência',
        ddressesUsed: 'Endereços utilizados',
        stock: 'Estoque',
        expedition: 'Expedição',
        insufficient: 'Insuficiente',
        requests: 'Pedidos',
        pickingProgress: 'Picking Andamento',
        divergentPicking: 'Picking Divergente',
        plannedPicking: 'Picking Planejado',
        resupply: 'Ressuprimento',
      },
      reportStock: {
        stored: 'Armazenado',
        shipped: 'Embarcado',
        invoiced: 'Faturado',
      },
      pack: {
        packCreatSuccess: 'Pack criado com sucesso',
        pack: 'Criar Pack',
        validatedSucces: 'EAN validados com sucesso!',
        errorValidated: 'Erro. Cor e ou produto são diferentes!',
      },
      streetOccupation: {
        totalOccupation: 'Total Ocupado',
        occupationStreet: 'Ocupação',
        occupation: 'Ocupado',
        empty: 'Vazio',
      },
      commom: {
        storage: 'Armazenagem',
        picking: 'Picking',
        detail: 'Detalhe',
        details: 'Detalhes',
        actions: 'Ações',
        user: 'Usuário',
        order: 'Pedido',
        status: 'Status',
        initialDate: 'Data Inicial',
        startTime: 'Hora de Início',
        finalDate: 'Data Final',
        endTime: 'Hora do Fim',
        totalTime: 'Tempo Total',
        shippingCompany: 'Transportadora',
        branch: 'Filial',
        progress: 'Progresso',
        records: 'registro(s)',
        product: 'Produto',
        descriptionProduct: 'Descrição do Produto',
        box: 'Caixa',
        pallet: 'Palete',
        grid: 'Grade',
        size: 'Tamanho',
        invoice: 'Nota Fiscal',
        recordsFound: 'Foi encontrado',
        client: 'Cliente',
        edit: 'Editar',
        saveButtonTitle: 'Salvar',
        orderType: 'Tipo Pedido',
        resultsNotFound: 'Resultados não encontrados.',
        period: 'Periodo',
        orderDate: 'Data Pedido',
        yes: 'Sim',
        no: 'Não',
        create: 'Criar',
        followUp: 'Acompanhamento',
        wave: 'Onda',
        color: 'Cor',
        quantity: 'Quantidade',
        toClean: 'Limpar',
        search: 'Pesquisar',
        finalize: 'Finalizar',
        code: 'Código',
        successFilter: 'resultado(s) encontrados',
        description: 'Descrição',
        error: 'Erro',
        branchsNotName: 'Não há filiais com esse nome!',
        toAdd: 'Adicionar',
        deposit: 'Deposito',
        level: 'Nivel',
        stock: 'Estoque',
        reports: 'Relatorios',
        dashboard: 'Dashboard',
        autenticate: 'Autenticação',
        seller: 'Vendedor',
        formPayment: 'Forma de pagamento',
        total: 'Total',
        barCode: 'Código de barras',
        enterBarCode: 'Digite o código de barras',
        print: 'Impressora',
        printMsg: 'Impressão enviada. Por favor, verifique!',
        cage: 'Gaiola',
        expirationData: 'Data de validade',
        quantityDivergente: 'Qtde. Divergente',
        storageRequest: 'Pedido Armazenagem',
        quantityPack: 'Qtd. Pack',
        totalParts: 'Total Peça',
        alertAllFields: 'Preencha todos os campos!',
        class: 'Classe',
        model: 'Modelo',
        selectStreet: 'Selecione Rua',
        conect: 'Conectar',
        observation: 'Observação',
        heatMap: 'Mapa de Calor',
        distribution: 'Distibuição',
        pleaseFill: 'Preencha pelo menos um dos campos!',
        downloadFile: 'Baixar arquivo',
        onlyLeader: 'Somente lider!',
      },
      integration: {
        title: 'Monitoramento',
        subTitle: 'Processos',
        process: 'Processo',
        last_sync_at: 'Última Execução',
        last_sync_status: 'Última Execução Sucesso',
        next_sync_at: 'Próxima Execução',
        running: 'Executando',
        stopped: 'Parado',
        error: 'Erro',
      },
      followAge: {
        daysInStock: 'Dias em estoque',
        tenDays: '10 Dias',
        twentyDays: '20 Dias',
        thirtyDays: '30 Dias',
      },
      followWave: {
        followWave: 'Acompanhamento de onda',
        amountOrder: 'Qtde. Pedidos',
        amountProduct: 'Qtde. Produtos',
        waveDate: 'Data Onda',
      },
      initialIntegration: {
        module: 'Módulo',
        autorization: 'Autorização',
        inventoryDate: 'Data Inventário',
        createdDateInventory: 'Data Criação',
        selectFile: 'Selecione o Arquivo',
        newInventoryTitle: 'Novo Inventário',
        inventory: 'Inventário',
        inventoryCode: 'Código Inventário',
        home: 'Inicial',
        selectClass: 'Seleciona a Classe',
        detailInventory: 'Detalhes Inventário',
        firstCollect: 'Primeira Coleta',
        lastCollectt: 'Ultima Coleta',
        message: 'Menssagem',
        errorMessage: 'Error ao criar inventário nos endereços abaixo!',
        notFoundMessage:
          'Resultados não encontrados. Verifique filtro ou Inventário selecionado!',
        selectInventory: 'Selecione um Inventário!',
      },
      scoreInventory: {
        score: 'Contagem',
        bar: 'Barra',
        brand: 'Marca',
        reference: 'Referência',
        notScoreMsg: 'Este Inventário não iniciou a contagem!',
        totalAddresses: 'Total de Endereços',
        firstCount: 'Primeira Contagem',
        secondCount: 'Segunda Contagem',
        thistCount: 'Terceira Contagem',
        consolidated: 'Consolidado',
        countedAddresses: 'Endereços Contados',
        itemsCounted: 'Itens Contados',
        skusCounted: 'SKUs Contados',
        totalConsolidated: 'Total Consolidados',
        notFoundAddress: 'Inventário não contém endereços!',
      },
      finallyInventory: {
        finallyInventory: 'Finalizar Inventário',
        reopenInventory: 'Reabrir Invetário',
        updateStock: 'Atualizar Estoque WMS',
        updateMessage: 'Estoque do WMS atualizado com sucesso!',
        warnningMessage:
          'Tem certeza de que deseja atualizar o estoque do WMS com o consolidado do Inventário?',
      },
      divergentInventory: {
        difference: 'Diferença',
        statusCollect: 'Status Coleta',
        statusAddress: 'Status Endereço',
        divergentAddress: 'Divergência Endereço',
        piecesCounted: 'Peças Contadas',
        productDivergent: 'Produto Divergente',
      },
      statusAddress: {
        addressCode: 'Codigo Endereço',
        addressResetMsg: 'Endereço zerado com sucesso!',
        confirmResetMsg: 'Confirma zerar o endereço',
      },
      printOutBox: {
        generateBox: 'Gerar',
        header: 'Cabeçalho',
        selectAgent: 'Selecione o Agente',
        conectedSuccesfully: 'Agente conectado com sucesso',
        alertMsg: 'Filtre por descrição e ou produto!',
        purchaseOrder: 'Pedido de Compra',
      },
      parametrization: {
        parameterize: 'Parametrizar',
        collected: 'Coletados',
        resetProductMsg: 'Produto zerado com sucesso!',
        questionReset: 'Deseja zerar o produto',
        theAddress: 'no endereço',
      },
      printWave: {
        waveCode: 'Código da Onda',
        planningDate: 'Data de Planejamento',
      },
      printDanfe: {
        keyInvoice: 'Chave NF',
        corporateName: 'Razão Social',
        stateRegistration: 'Inscrição Estadual',
        typeNote: 'Tipo de Nota',
        totalValue: 'Valor Total',
        issuanceDate: 'Data Emissão',
      },
      printMail: {
        mail: 'Correios',
        name: 'Nome Destinatário',
        observation: 'OBS. Destinatário',
        description: 'Desc. Destinatário',
        number: 'Num. Remetente',
        neighborhood: 'Bairro Remetente',
        zipCode: 'Cep. Remetente',
        city: 'Cidade Remetente',
        shipping: 'Desc. Transportadora',
      },
      indicators: {
        indicator: 'Indicador',
        alertSearch: 'Filtre por no máximo 31 dias e selecione o tipo!',
        table: 'Mesa',
        pricessing: 'Processamento',
        peoples: 'Pessoas',
        process: 'Processado',
        manPerDay: 'Homen Dia',
        price: 'Preço',
        alarm: 'Alarme',
      },
      docking: {
        screening: 'Triagem',
        pieces: 'Peças',
        driver: 'Motorista',
        maturyti: 'Vencimento',
        newRoute: 'Nova Rota',
        transport: 'Transporte',
        routes: 'Rotas',
        capacity: 'Capacidade',
        createRoute: 'Criar Rota',
        editRoute: 'Editar Rota',
      },
      quality: {
        quality: 'Qualidade',
        parameters: 'Parâmetros',
        editParameters: 'Editar Parâmetro',
        salveParameters: 'Salvar Parâmetros',
        newParameters: 'Novo Parâmetro',
        descriptionsTitle: 'Descrição do Parâmetro',
        defaultTitle: 'Frequência de amostragem para controle de qualidade',
        fromBox: 'De X Caixas',
        upTheBox: 'Até X Caixas',
        quantityQuality: 'Qtde. Qualidade',
        deleteParameters: 'Excluir Parâmetro',
      },
    },
    components: {
      search: {
        title: 'Pesquisar',
        clear: 'Limpar',
        advancedFilters: 'Filtros Avançados',
        closeAdvancedFilters: 'Menos Filtros',
        upload: 'Upload de Arquivos',
        loading: 'Carregando',
        filter: 'Filtros',
      },
      table: {
        export: 'Exportar',
        entries: 'Registros',
        to: 'a',
        noResults: 'Não foi encontrado nenhum registro',
      },
      user: {
        greetings: 'Olá',
      },
      pageMode: {
        close: 'Fechar',
        back: 'Voltar',
        printOut: 'Imprimir',
      },
      confirmationModal: {
        close: 'Fechar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      userPopper: {
        profile: 'Perfil',
        config: 'Configurações',
      },
    },
  },
};
